<template>
  <div class="d-flex flex-row">
    <div class="card card-custom col-12 p-0">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Cambiar contraseña
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1"
            >Actualiza la clave de acceso</span
          >
        </div>
        <!-- <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          :disabled="!isValidForm || isPasswordMatch"
          @click="save()"
          ref="kt_save_changes"
        >
          Actualizar
        </button>
        <a type="reset" class="btn btn-secondary" href="#/users">
          Cancelar
        </a>
      </div> -->
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <div class="flex-row-fluid">
        <!--begin::Card-->
        <div class="">
          <!--begin::Form-->
          <form class="form">
            <!--begin::Body-->
            <div class="card-body">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Contraseña actual</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    ref="current-password"
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    v-model="user.currentPassword"
                  />
                  <div
                    class="error text-primary"
                    v-if="!$v.user.currentPassword.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.user.currentPassword.minLength"
                  >
                    Son requeridos por lo menos 8 caracteres.
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Nueva contraseña</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    ref="new-password"
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    v-model="user.password"
                  />
                  <div
                    class="error text-primary"
                    v-if="!$v.user.password.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.user.password.minLength"
                  >
                    Son requeridos por lo menos 8 caracteres.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Repetir nueva contraseña</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    ref="re-new-password"
                    :class="
                      user.password != '' && isPasswordMatch
                        ? 'is-invalid border-danger form-control form-control-lg form-control-solid'
                        : 'form-control form-control-lg form-control-solid'
                    "
                    type="password"
                    v-model="user.repeatPassword"
                  />
                  <div
                    class="error text-primary"
                    v-if="!$v.user.repeatPassword.sameAsPassword"
                  >
                    Las contraseñas deben ser idénticas.
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="!!user.id">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Fecha de creación</label
                >
                <div
                  class="col-lg-9 col-xl-6"
                  style="display: flex;justify-content: center;flex-direction: column;"
                >
                  {{ user.createdAt }}
                </div>
              </div>
            </div>
            <!--end::Body-->
            <!--end::Form-->
            <!--begin::Footer-->

            <div class="card-toolbar text-right mb-10 pr-5">
              <button
                type="reset"
                class="btn btn-success mr-2"
                :disabled="$v.user.$invalid"
                @click="save()"
                ref="kt_save_changes"
              >
                Actualizar
              </button>
              <a type="reset" class="btn btn-secondary" href="#/users">
                Cancelar
              </a>
            </div>
          </form>
          <!--end::Form-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "UserProfile",
  data() {
    return {
      resource: "admins",
      user: {
        password: "",
        currentPassword: "",
        repeatPassword: ""
      },
      default_photo: "media/users/blank.png",
      current_photo: null
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Usuarios", route: "/users" },
      { title: "Perfil" },
      { title: "Clave de acceso" }
    ]);
  },
  validations: {
    user: {
      currentPassword: {
        required,
        minLength: minLength(8)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        sameAsPassword: sameAs("password")
      }
    }
  },

  methods: {
    async getResource(id) {
      let { data } = await ApiService.get(`${this.resource}`, id);
      //console.log(data);
      return data;
    },

    async save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // dummy delay
      setTimeout(async () => {
        // send update request
        let currentUser = this.user;
        let response;
        try {
          response = await ApiService.post(
            `${this.resource}/me/change-password`,
            currentUser
          );
          console.log("RESPONSE", response);
        } catch (e) {
          console.log("ERROR", e);

          Swal.fire({
            title: "",
            text: `La contraseña actual ingresada es incorrecta!`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.user = {
            password: "",
            currentPassword: "",
            repeatPassword: ""
          };
          return;
        }

        Swal.fire({
          title: "",
          text: "La contraseña ha sido actualizada exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;

        this.user = {
          password: "",
          currentPassword: "",
          repeatPassword: ""
        };
      }, 2000);
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    isPasswordMatch() {
      return this.user.repeatPassword !== this.user.password;
    },
    isValidForm() {
      return (
        this.user.password !== "" &&
        this.user.currentPassword !== "" &&
        this.user.repeatPassword !== ""
      );
    }
  }
};
</script>
